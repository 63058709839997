<template>
  <Layout class="MenuEdit">
    <el-form
      ref="subFormData"
      :model="subFormData"
      size="small"
      label-width="150px"
      label-position="left"
      class="form"
    >
      <el-form-item label="外显包:">
        <el-checkbox-group
          v-model="subFormData.app_keys"
          @change="handleCheckboxChange"
        >
          <el-checkbox
            v-for="(item, key) in appKeysNameMap"
            :key="key"
            :label="key"
          >
            {{ item }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <template v-if="subFormData.app_keys.length">
        <div
          v-for="(key, item) in appKeysNameMap"
          :key="item"
        >
          <el-form
            v-if="subFormData.app_keys.indexOf(item) > -1"
            ref="subFormDataSettings"
            :model="subFormData.settings[item]"
            label-width="150px"
            :rules="rules"
          >
            <el-divider />
            <div style="margin:20px 0 30px 120px;font-weight:bold">
              {{ `${key}配置` }}
            </div>
            <el-form-item label="强更状态">
              <el-select v-model="subFormData.settings[item].force_update">
                <el-option
                  v-for="(regionTypeitem, regionTypekey) in clickType"
                  :key="regionTypekey"
                  :label="regionTypeitem"
                  :value="regionTypekey"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="最新版本号"
              prop="latest_version"
            >
              <el-input
                v-model="subFormData.settings[item].latest_version"
                placeholder="请输入最新版本号"
              />
            </el-form-item>
            <el-form-item
              label="最低版本号"
              prop="lowest_version"
            >
              <el-input
                v-model="subFormData.settings[item].lowest_version"
                placeholder="请输入最低版本号"
              />
            </el-form-item>
            <el-form-item label="更新文案">
              <el-input
                v-model="subFormData.settings[item].description"
                placeholder="更新文案"
                type="textarea"
                :rows="2"
              />
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-form-item>
        <el-button
          :loading="addLoading"
          @click="onCancel"
        >
          取消
        </el-button>
        <el-button
          :loading="addLoading"
          type="primary"
          @click="onSubmit"
        >
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </Layout>
</template>

<script>
import { commonRequestGet } from '@/api/commonApi.js'
import { debounce } from '@/utils'
import { getUpdates, submitUpdates } from '@/api/forceUpdate.js'
const clickType = {
  true: '开启',
  false: '关闭'
}

export default {
  components: {},
  data () {
    return {
      row: '',
      clickType,
      subFormData: {
        app_keys: [],
        settings: {}
      },
      rules: {
        latest_version: [{ required: true, message: '必填', trigger: 'blur' }],
        lowest_version: [{ required: true, message: '必填', trigger: 'change' }]
      },
      addLoading: false,
      appKeysNameMap: []
    }
  },
  watch: {
    'subFormData.app_keys': {
      handler (newValue) {
        const updatedSettings = {}
        newValue.forEach((item) => {
          if (this.subFormData.settings && this.subFormData.settings[item]) {
            updatedSettings[item] = this.subFormData.settings[item]
          } else {
            updatedSettings[item] = {
              force_update: 'open',
              description: '',
              latest_version: '',
              lowest_version: ''
            }
          }
        })
        this.subFormData.settings = updatedSettings
      },
      immediate: true,
      deep: true
    }
  },
  async created () {
    this.getUpdates()
    await this.getAppKeys()
  },

  methods: {
    getUpdates () {
      getUpdates().then((res) => {
        this.subFormData = res
      })
    },
    async getAppKeys () {
      const res = await commonRequestGet('admin/app-review', this.formData)
      this.appKeysList = res?.data ?? []
      this.filteredAppKeysList = this.appKeysList.filter((item) => item.rank !== 0)
      this.appKeysList = this.filteredAppKeysList.map((item) => {
        return {
          app_key: item.app_id,
          name: item.app_name
        }
      })
      this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
        result[item.app_key] = item.name
        return result
      }, {})
    },
    handleCheckboxChange (checkedKeys) {
      console.log(checkedKeys, 'checkedKeys')
      const updatedSettings = {}
      for (const key in this.subFormData.settings) {
        if (checkedKeys.includes(key)) {
          updatedSettings[key] = this.subFormData.settings[key]
        }
      }
      this.subFormData.settings = updatedSettings
      console.log(this.subFormData.settings, 'this.subFormData.settings')
    },
    onSubmit: debounce(async function () {
      try {
        this.addLoading = true
        let hasError = false
        for (const key in this.$refs.subFormDataSettings) {
          const item = this.$refs.subFormDataSettings[key]
          await item.validate()
          if (item.validateState === 'error') {
            hasError = true
          }
        }
        if (hasError) {
          this.$message.error('验证未通过，请重新输入')
          this.getUpdates()
        } else {
          await submitUpdates({ app_keys: this.subFormData.app_keys, settings: this.subFormData.settings })
          this.$message.success('保存成功')
          await this.getUpdates()
        }
      } catch (error) {
        this.$message.error(error)
      } finally {
        this.addLoading = false
      }
    }, 300),

    onCancel () {
      this.getUpdates()
    }
  }
}
</script>

<style lang="less">
.MenuEdit {
  min-width: 970px;
  .layout-content {
    padding: 20px 60px 80px !important;
  }
  .form {
    .nonfocal-box {
      display: flex;
      flex-direction: row;
      .nonfocal {
        width: 340px;
        .image .el-upload--picture-card {
          width: 100px;
          height: 100px;
        }
        .image .el-upload-list__item {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  .el-input,
  .el-textarea {
    width: 320px;
  }
  .el-input-number {
    .el-input {
      width: auto;
    }
  }
  .ctrl-area {
    margin-top: 80px;
    .el-button {
      padding: 10px 40px;
    }
  }
}
</style>

import request from '@/utils/request'

export function getUpdates (params) {
  return request({
    url: 'admin/app-updates',
    params,
    method: 'get',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
export function submitUpdates (data) {
  return request({
    url: 'admin/app-updates',
    data,
    method: 'post',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
